import { isFunction } from 'lodash'

// @TODO: type action!
const createReducer =
  <T>(initialState: T, handlers: { [key: string]: (state: T, action: any) => T }) =>
  // eslint-disable-next-line @typescript-eslint/default-param-last
  (state = initialState, action: any) => {
    if (
      action &&
      Object.prototype.hasOwnProperty.call(handlers, action.type) &&
      isFunction(handlers[action.type])
    ) {
      return handlers[action.type](state, action)
    }
    return state
  }

export default createReducer
